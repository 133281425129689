<template>
  <div class="d-flex" style="height: 100%; max-height: 100%">
    <div class="statisticContainer">
      <div :style="(isShowFilter || !$vuetify.breakpoint.xs)?'height: unset;':'height: 56px; overflow: hidden;'"
           class="d-flex flex-wrap justify-sm-center pl-3 pl-sm-0 mt-3"
           style="width: 100%; background-color: rgba(65, 149, 24, 0.2); position: relative;">
        <div v-if="$vuetify.breakpoint.xs" style="position: absolute; top: 4px; right: 8px;">
          <v-icon class="white rounded-xl" style="font-size: 18px;" @click="isShowFilter=!isShowFilter">
            {{ (isShowFilter) ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
        </div>
        <div v-if="$vuetify.breakpoint.xs && !isShowFilter" class="py-1" style="height: 56px; width: 100%;">
          <div v-for="label in filterLabel" :key="label" class="text-center" style="width: 100%;">
            {{ label }}
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pl-sm-3 pr-3 py-2">
            ตั้งแต่วันที่
          </div>
          <div class="myInputWidth pr-3 py-2">
            <my-date-picker v-model="datefrom" :max="dateto" min="" buttonColor="primary white--text" color="primary"
                            header-color="primary"/>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pl-sm-3 pr-3 py-2">
            ถึงวันที่
          </div>
          <div class="myInputWidth pr-3 py-2">
            <my-date-picker v-model="dateto" :min="datefrom" buttonColor="primary white--text" color="primary"
                            header-color="primary"/>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            ประเภทการตรวจวัด
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-select v-model="type" :items="measurementType" background-color="white" dense hide-details
                      item-text="name" item-value="id" outlined placeholder="ทั้งหมด"
                      @change="selectedParameter=''"/>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            พารามิเตอร์
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedParameter" :items="forSelectParameter" background-color="white" dense
                            hide-details item-text="name" item-value="id" outlined
                            placeholder="--เลือก--"></v-autocomplete>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            หน่วย
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedUnit" :items="forSelectUnit" background-color="white" dense
                            hide-details item-text="name" item-value="id" outlined
                            placeholder="--เลือก--"></v-autocomplete>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            ภูมิภาค
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-select v-model="selectedRegion" :items="forSelectedRegion" background-color="white" dense hide-details
                      item-text="name" item-value="id" outlined placeholder="ทั้งหมด"
                      @change="selectedProvince=''"/>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            จังหวัด
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedProvince" :items="forSelectProvince"
                            background-color="white" dense hide-details item-text="name" item-value="id" outlined
                            placeholder="ทั้งหมด" @change="selectedFactory=''"></v-autocomplete>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            ประเภทโรงงาน
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedFactoryType" :items="forSelectFactoryType"
                            background-color="white" dense hide-details item-text="name" item-value="id" outlined
                            placeholder="ทั้งหมด" @change="selectedFactory=''; selectedMeasurement=''"></v-autocomplete>
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="myLabelWidth d-flex align-center search--text pr-3 py-2">
            สถานที่ตรวจวัด
          </div>
          <div class="myInputWidth pr-3 py-2">
            <v-autocomplete v-model="selectedFactory" :items="forSelectFactory"
                            background-color="white" dense hide-details item-text="name" item-value="id" outlined
                            placeholder="ทั้งหมด"></v-autocomplete>
          </div>
        </div>
        <div class="pr-3 py-2">
          <v-btn color="primary white--text" style="height: 40px;" @click="get()">
            ค้นหา
            <v-progress-circular v-show="isLoading" class="ml-3" indeterminate size="16"></v-progress-circular>
          </v-btn>
        </div>
      </div>
      <div class="pt-2">
        <v-row class="px-3 ma-0">
          <v-col class="pa-1 pa-sm-3" cols="12" sm="">
            <div class="rounded-lg fill-height pa-2 white--text" style="background-color: #EF3636;">
              <div id="largest-text-div" style="min-width: 90px;">สถานที่/จุดตรวจวัด</div>
              <div class="justify-center d-flex">
                <span class="bigNumber mx-1 my-2">
                  {{ numberWithCommas(factoryCount) }}/{{ numberWithCommas(countMeasurement) }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col class="pa-1 pa-sm-3" cols="12" sm="">
            <div class="rounded-lg fill-height pa-2 white--text" style="background-color: #1F73AA;">
              <div :style="`min-height: ${divHeight}px;`" style="min-width: 90px;">ค่าเฉลี่ย</div>
              <div class="justify-center d-flex">
                <span class="bigNumber mx-1 my-2"> {{ numberWithCommas(averageValue) }} </span>
              </div>
            </div>
          </v-col>
          <v-col class="pa-1 pa-sm-3" cols="12" sm="">
            <div class="rounded-lg fill-height pa-2 white--text" style="background-color: #EE9A1F;">
              <div :style="`min-height: ${divHeight}px;`" style="min-width: 90px;">ค่าสูงสุด</div>
              <div class="justify-center d-flex">
                <span class="bigNumber mx-1 my-2"> {{ numberWithCommas(maxValue) }} </span>
              </div>
            </div>
          </v-col>
          <v-col class="pa-1 pa-sm-3" cols="12" sm="">
            <div class="rounded-lg fill-height pa-2 white--text" style="background-color: #F05454;">
              <div :style="`min-height: ${divHeight}px;`" style="min-width: 90px;">ค่าต่ำสุด</div>
              <div class="justify-center d-flex">
                <span class="bigNumber mx-1 my-2"> {{ numberWithCommas(minValue) }} </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="pa-3 pt-5 pt-sm-3">
        <div id="barChartContainer"></div>
      </div>
    </div>
    <div class="mapContainer">
      <div style="position: relative; width: 100%; height: 100%;">
        <thai-map-svg ref="map" v-on:mouseLeave="doLeave" v-on:provinceClick="doClick" v-on:provinceHover="doHover"/>
        <div v-show="isShowDescription" :style="descriptionStyle" class="grey white--text py-1 px-2"
             style="position: absolute;">
          {{ description.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'
import ThaiMapSvg from '../thaiMapSvg'
import MyDatePicker from '../myDatePicker'
import { mapState } from 'vuex'
import Highcharts from 'highcharts'
import Exporting from 'highcharts/modules/exporting'

Exporting(Highcharts)

export default {
  name: 'DailyEReport',
  components: {
    MyDatePicker,
    ThaiMapSvg
  },
  async mounted () {
    function sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)

    this.isLoading = true
    this.type = 1 // invoke watcher

    this.markProvince()
    this.$store.dispatch('ref/getFactoryType').then(() => {}) // do not wait this line
    await sleep(100)
    await this.get()

    this.isLoading = false
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      measurementType: [
        { id: 1, name: 'น้ำ' },
        { id: 2, name: 'แหล่งน้ำธรรมชาติ' },
        { id: 3, name: 'อากาศ' },
        { id: 4, name: 'ระดับเสียง' }
      ],
      // --
      isShowFilter: true,
      divHeight: 48,
      type: '',
      selectedParameter: '',
      forSelectParameter: [],
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      selectedRegion: '',
      selectedProvince: '',
      selectedMeasurement: '',
      selectedUnit: '',
      factoryCount: '',
      countMeasurement: '',
      selectedFactory: '',
      averageValue: '',
      maxValue: '',
      minValue: '',
      isShowDescription: false,
      description: { // province name description dialog, use in doHover(e)
        x: 0,
        y: 0,
        title: '',
        text: ''
      },
      isLoading: false,
      forSelectFactory: [],
      selectedFactoryType: ''
    }
  },
  methods: {
    reCalculateHeight () {
      const dom = document.getElementById('largest-text-div')
      if (dom) {
        this.divHeight = dom.offsetHeight
      }
    },
    numberWithCommas (x) {
      return utils.numberWithCommas(x || 0)
    },
    generateChart () {
      const parameter = this.forSelectParameter.find(item => String(item.id) === String(this.selectedParameter)) || {}
      const unit = this.forSelectUnit.find(item => String(item.id) === String(this.selectedUnit)) || {}
      const textStyle = {
        // fontSize: '16px',
        // fontWeight: 'bold',
        fontFamily: 'Roboto, Prompt, sans-serif'
      }
      Highcharts.chart('barChartContainer', {
        chart: {
          type: 'column'
        },
        title: {
          text: 'สถิติ e-report',
          style: textStyle
        },
        xAxis: {
          type: 'category',
          title: {
            text: parameter.name || '',
            style: textStyle
          },
          labels: {
            style: textStyle
          }
        },
        yAxis: {
          title: {
            text: unit.name || '',
            style: textStyle
          }
        },
        legend: {
          enabled: false
        },
        series: [
          {
            name: parameter.name || '',
            data: [
              { name: 'ค่าเฉลี่ย', y: this.averageValue, color: '#1F73AA' },
              { name: 'ค่าสูงสุด', y: this.maxValue, color: '#EE9A1F' },
              { name: 'ค่าต่ำสุด', y: this.minValue, color: '#F05454' }
            ]
          }
        ]
      })
    },
    async get () {
      const payload = {
        uuid: this.uuid,
        datefrom: this.datefrom,
        dateto: this.dateto,
        type: this.type || '',
        parameter: this.selectedParameter,
        unit: this.selectedUnit
      }
      if (this.selectedRegion) {
        payload.region = this.selectedRegion
      }
      if (this.selectedProvince) {
        payload.geocode = this.selectedProvince
      }
      if (this.selectedFactory) {
        payload.sampling_location = this.selectedFactory
      }
      const data = await this.$store.dispatch('statistic/getEReportStat', payload)
      this.factoryCount = data.countSamplingLocation
      this.countMeasurement = data.countSamplingPoint
      this.averageValue = data.average
      this.maxValue = data.max
      this.minValue = data.min
      this.generateChart()
    },
    doHover (e) {
      this.description.x = e.offsetX
      this.description.y = e.offsetY
      this.description.title = e.name_th
      this.isShowDescription = true
    },
    doLeave (e) {
      this.isShowDescription = false
    },
    doClick (e) {
      const provinceId = parseInt(String(e).substr(3, 4))
      const provinceObject = this.province.find(item => item.id === provinceId)
      this.selectedRegion = provinceObject.id_parent
      this.selectedProvince = provinceObject.id
    },
    markProvince () {
      this.$refs.map.markProvince(0)
    }
  },
  computed: {
    ...mapState('user', ['permission', 'uuid', 'roles', 'factory_id']),
    ...mapState('ref', ['parameter', 'parameterList', 'factoryType']),
    ...mapState(['province', 'region']),
    filterLabel () {
      const ret = []
      ret.push(`วันที่ - ${utils.dateToThai(this.datefrom)} ถึง ${utils.dateToThai(this.dateto)}`)
      const parameter = this.forSelectParameter.find(item => String(item.id) === String(this.selectedParameter)) || {}
      if (parameter && parameter.name) {
        const unit = this.forSelectUnit.find(item => item.id === this.selectedUnit).name
        ret.push(`พารามิเตอร์ - ${parameter.name} (${unit})`)
      }
      return ret
    },
    descriptionStyle () {
      return 'top:' + (this.description.y) + 'px; left:' + (this.description.x) + 'px;'
    },
    forSelectedRegion () {
      return [{ id: null, name: 'ทั้งหมด' }, ...this.region]
    },
    forSelectProvince () {
      if (this.selectedRegion) {
        return [{ id: null, name: 'ทั้งหมด' }, ...this.province.filter(item => item.id_parent === this.selectedRegion)]
      }
      return [{ id: null, name: 'ทั้งหมด' }, ...this.province]
    },
    forSelectUnit () {
      if (this.selectedParameter) {
        return this.forSelectParameter.find(item => item.id === this.selectedParameter).unit
      } else {
        return []
      }
    },
    factoryWatcherIndicator () {
      return this.selectedRegion + '_' + this.selectedProvince + '_' + this.type + '_' + this.selectedFactoryType
    },
    forSelectFactoryType () {
      return [{ id: null, name: 'ทั้งหมด' }, ...this.factoryType]
    }
  },
  watch: {
    async type (to, from) {
      this.forSelectParameter = await this.$store.dispatch('ref/getEntireParameter', { type: to })
    },
    forSelectUnit (to, from) {
      if (to.length >= 1) {
        this.selectedUnit = to[0].id
      }
    },
    async factoryWatcherIndicator (to, from) {
      const payload = {}
      if (this.selectedRegion) {
        payload.region = this.selectedRegion
      }
      if (this.selectedProvince) {
        delete payload.region
        payload.province = this.selectedProvince
      }
      if (this.type) {
        payload.type = this.type
      }
      if (this.selectedFactoryType) {
        payload.factoryType = this.selectedFactoryType
      }
      const result = await this.$store.dispatch('ref/getSamplingLocation', payload)
      this.forSelectFactory = [{ id: '', name: 'ทั้งหมด' }, ...result]
    },
    forSelectParameter (to, from) {
      if (to.length >= 1) {
        this.selectedParameter = to[0].id
      }
    }
  }
}
</script>

<style scoped>

@media (max-width: 600px) {
  .statisticContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .mapContainer {
    width: 0;
    height: 0;
    max-height: 100%;
  }

  .myLabelWidth {
    width: 105px;
  }

  .myInputWidth {
    max-width: calc(100vw - 12px - 105px - 86px);
  }
}

@media (min-width: 600px) {
  .statisticContainer {
    width: calc(100% - min(600px, 40%));
    max-height: 100%;
    overflow-y: auto;
  }

  .mapContainer {
    width: min(600px, 40%);
    max-height: 100%;
    padding: 12px;
  }

  .myInputWidth {
    max-width: 170px;
  }
}

.bigNumber {
  font-size: 36px;
  line-height: 1;
}

::v-deep .v-select__selections {
  line-height: unset;
}

::v-deep .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__title {
  line-height: unset;
}

::v-deep .v-list-item__content > * {
  line-height: unset;
}
</style>
