<template>
  <div class="fill-height">
    <div class="d-block d-sm-none">
      <v-btn :ripple="false" class="pl-0" color="white" elevation="0" large
             @click="$emit('openMenu', true)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <div class="componentHeight">
      <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs" background-color="primary" centered grow hide-slider>
        <v-tab v-for="item in filteredTabs" :key="item.text" :disabled="item.disabled" class="white--text">
          {{ item.text }}
          <a :href="getHref(item.ref)" class="a_forRightClick" @click.prevent="changeQuery(item.ref)"></a>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="contentItem" touchless>
        <v-tab-item v-for="item in filteredTabs" :key="item.text">
          <div style="height: 100%">
            <component :is="item.component" :ref="item.ref"></component>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import air from '../components/ereport/air'
import mineralWater from '../components/ereport/mineralWater'
import noisePollution from '../components/ereport/noisePollution'
import water from '../components/ereport/water'
import { mapState } from 'vuex'

export default {
  name: 'EReport',
  beforeMount () {
    const ref = this.$route.query.reference
    this.tab = this.tabsData.findIndex(item => item.ref === ref)
  },
  beforeRouteLeave (to, from, next) {
    // this page has been cached in app.vue
    // if next page is not ViewReport, remove cache
    // for more information see
    //     App.vue :: <keep-alive>
    //     router/index.js :: meta: { keepAlive }
    //     store/index.js :: state: { keepAliveResetSignal }
    if (to.name === 'ViewReport') {
      next()
    } else {
      next()
      this.$store.dispatch('refreshKeepAlive')
    }
  },
  data () {
    return {
      tab: 0,
      tabsData: [
        {
          text: 'น้ำ',
          component: water,
          ref: 'water',
          require: '',
          disabled: false
        },
        {
          text: 'แหล่งน้ำธรรมชาติ',
          component: mineralWater,
          ref: 'mineralWater',
          require: '',
          disabled: false
        },
        {
          text: 'อากาศ',
          component: air,
          ref: 'air',
          require: '',
          disabled: false
        },
        {
          text: 'ระดับเสียง',
          component: noisePollution,
          ref: 'noise',
          require: '',
          disabled: false
        }
      ]
    }
  },
  methods: {
    getHrefPayload (ref) {
      return { reference: ref }
    },
    changeQuery (reference) {
      const payload = this.getHrefPayload(reference)
      this.$router.push({
        name: this.$route.name,
        query: payload
      })
        .catch(() => {
        }) // remove error from console log // this error mostly appear when navigate on same route
    },
    getHref (ref) {
      const payload = this.getHrefPayload(ref)
      return `?${new URLSearchParams(payload).toString()}`
    }
  },
  watch: {
    tab (newVal, oldVal) {
      if (this.$refs.pp && newVal === 1) {
        setTimeout(this.$refs.pp.resizeEvent, 500)
      }
    }
  },
  computed: {
    ...mapState('user', ['permission']),
    filteredTabs () {
      const disabledTabs = this.tabsData.map(item => ({
        ...item,
        disabled: item.disabled && !this.selectedFactory.id
      }))
      return disabledTabs.filter(item => this.permission.includes(item.require) || item.require === '')
    }
  }
}
</script>

<style scoped>

@media (max-width: 600px) {
  .componentHeight {
    height: calc(100% - 44px);
  }
}

@media (min-width: 600px) {
  .componentHeight {
    height: 100%;
  }
}

::v-deep .v-slide-group__next > i {
  color: white !important;
}

::v-deep .v-slide-group__next > .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0) !important;
}

::v-deep .v-slide-group__prev > i {
  color: white !important;
}

::v-deep .v-slide-group__prev > .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0) !important;
}

.contentItem {
  height: calc(100% - 48px);
}

::v-deep .v-window__container, .v-window-item {
  height: 100%;
}

::v-deep .v-tab--active {
  background: rgb(158, 137, 77);
  background: -moz-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9e894d", endColorstr="#b7a676", GradientType=1);
}
</style>
