<template>
  <v-dialog v-model="isOpenDialog" :fullscreen="$vuetify.breakpoint.xs"
            :max-width="(!querySuccess && !queryFail) ? 800 : 600" :scrollable="$vuetify.breakpoint.xs"
            :transition="($vuetify.breakpoint.xs) ? 'dialog-bottom-transition' : 'dialog-transition'">
    <v-card>
      <v-card-title v-if="!querySuccess && !queryFail">
        <span v-if="mode === 'add'" class="secondary--text">เพิ่มสถานที่ตรวจวัด</span>
        <span v-if="mode === 'edit'" class="secondary--text">แก้ไขสถานที่ตรวจวัด</span>
        <span v-if="mode === 'view'" class="secondary--text">สถานที่ตรวจวัด</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-card-title v-else>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider v-if="!querySuccess && !queryFail"/>
      <v-card-text class="pa-0">
        <div v-show="!querySuccess && !queryFail" class="py-3 py-sm-5 px-6 px-sm-9 black--text">
          <v-form ref="form" :readonly="mode==='view'">
            <v-row v-if="mode==='add' || (mode==='edit' && isSamplingPoint===false)">
              <v-col class="pb-0" cols="1">
                <v-radio-group v-model="isSamplingPoint" class="mt-0 pt-0" dense hide-details>
                  <v-radio :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="pl-sm-0 pb-0" cols="auto">
                โรงงาน
              </v-col>
              <v-col class="pl-sm-0" cols="11" offset="1">
                <v-autocomplete v-model="selectedFactory"
                                :background-color="(isSamplingPoint!==false) ? 'grey lighten-3' : 'white'"
                                :disabled="isSamplingPoint!==false" :items="factorySearchResult" :loading="isSearching"
                                :search-input.sync="factorySearchKeyword" dense item-text="name"
                                hint="กรุณาระบุคำค้นอย่างน้อย 3 ตัวอักษร" persistent-hint
                                item-value="id" outlined placeholder="โรงงาน" return-object></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="mode==='add'" class="pb-0" cols="1">
                <v-radio-group v-model="isSamplingPoint" class="mt-0 pt-0" dense hide-details>
                  <v-radio :value="'new'"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="mode==='add'" class="pl-sm-0 pb-0" cols="11">
                เพิ่มสถานที่ใหม่
              </v-col>
              <v-col :offset="(mode==='add') ? 1 : 0" class="pb-0 d-flex align-center" cols="4" sm="3">
                เลขทะเบียนโรงงาน (ถ้ามี)
              </v-col>
              <v-col :cols="(mode==='add') ? 7 : 8" class="pb-0" sm="8">
                <v-text-field v-model="no" :background-color="(isSamplingPoint!=='new') ? 'grey lighten-3' : 'white'"
                              :disabled="isSamplingPoint!=='new'" dense hide-details outlined></v-text-field>
              </v-col>
              <v-col :offset="(mode==='add') ? 1 : 0" class="pb-0 d-flex align-center" cols="4" sm="3">
                ชื่อโรงงาน/สถานที่ <span class="red--text">*</span>
              </v-col>
              <v-col :cols="(mode==='add') ? 7 : 8" class="pb-0" sm="8">
                <v-text-field v-model="name" :background-color="(isSamplingPoint!=='new') ? 'grey lighten-3' : 'white'"
                              :disabled="isSamplingPoint!=='new'"
                              :rules="(isSamplingPoint==='new') ? [rules.required] : []" dense hide-details
                              outlined></v-text-field>
              </v-col>
              <v-col :offset="(mode==='add') ? 1 : 0" class="pb-0 d-flex align-center" cols="4" sm="3">
                จังหวัด <span class="red--text">*</span>
              </v-col>
              <v-col :cols="(mode==='add') ? 7 : 8" class="pb-0" sm="8">
                <v-autocomplete v-model="selectedProvince"
                                :background-color="(isSamplingPoint!=='new') ? 'grey lighten-3' : 'white'"
                                :disabled="isSamplingPoint!=='new'" :items="province"
                                :rules="(isSamplingPoint==='new') ? [rules.required] : []" dense hide-details
                                item-text="name" item-value="id" outlined></v-autocomplete>
              </v-col>
              <v-col :offset="(mode==='add') ? 1 : 0" class="pb-0 d-flex align-center" cols="4" sm="3">
                ละติจูด
              </v-col>
              <v-col :cols="(mode==='add') ? 7 : 8" class="pb-0" md="" sm="8">
                <v-text-field v-model="lat" :background-color="(isSamplingPoint!=='new') ? 'grey lighten-3' : 'white'"
                              :disabled="isSamplingPoint!=='new'" dense hide-details outlined placeholder="ละติจูด"
                              type="Number"></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" :offset="(mode==='add') ? 1 : 0" offset-md="0" md="auto" class="pb-0 d-flex align-center">
                ลองจิจูด
              </v-col>
              <v-col :cols="(mode==='add') ? 7 : 8" class="pb-0" md="" sm="8">
                <v-text-field v-model="lon" :background-color="(isSamplingPoint!=='new') ? 'grey lighten-3' : 'white'"
                              :disabled="isSamplingPoint!=='new'" dense hide-details outlined placeholder="ลองจิจูด"
                              type="Number"></v-text-field>
              </v-col>
              <v-col v-if="mode !== 'add'" cols="1"></v-col>
            </v-row>
          </v-form>
        </div>
        <div v-show="querySuccess" class="py-9 success--text align-center justify-center text-center"
             style="font-size: 20px;">
          บันทึกสำเร็จ
        </div>
        <div v-show="queryFail" class="py-9 warning--text align-center justify-center text-center"
             style="font-size: 20px;">
          บันทึกไม่สำเร็จ ({{ errorMessage }})
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex justify-end pb-1" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn v-if="!querySuccess && !queryFail && mode!=='view'" class="ml-3" color="search-item white--text"
                 elevation="0"
                 @click="confirm()">
            บันทึก
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import projectConstant from '../../../assets/js/projectConstant'

export default {
  name: 'airDialog',
  props: {
    isShow: Boolean,
    mode: String,
    data: Object,
    type: Number
  },
  mounted () {
    this.$store.dispatch('getProvince')
  },
  data () {
    return {
      factorySearchKeyword: '',
      factorySearchResult: [],
      isSearching: false,
      isOpenDialog: false,
      isSamplingPoint: false,
      errorMessage: '',
      // --
      selectedFactory: { id: 0, name: '' },
      no: '',
      name: '',
      selectedProvince: 0,
      lat: '',
      lon: '',
      rules: projectConstant.rules,
      // --
      querySuccess: false,
      queryFail: false
    }
  },
  methods: {
    resetForm () {
      this.isSamplingPoint = null
      this.name = ''
      this.selectedFactory = { id: 0, name: '' }
      this.selectedProvince = 0
      this.lon = ''
      this.lat = ''
    },
    async get () {
      this.isSamplingPoint = 'new'
      const data = await this.$store.dispatch('eReport/getEReport', { uuid: this.uuid, sampling_location_id: this.data.id })
      this.name = data.name
      this.no = data.factoryNo
      if (data.province) {
        this.selectedProvince = data.province.id
      }
      if (data.geom) {
        const geom = String(data.geom).replace('POINT', '').replace('(', '').replace(')', '')
        const lonlat = geom.split(' ')
        this.lon = parseFloat(lonlat[0])
        this.lat = parseFloat(lonlat[1])
      }
    },
    async confirm () {
      const requiredPayload = { type: this.type, sampling_location_id: this.data.id }
      if (this.isSamplingPoint === false) { // โรงงาน
        requiredPayload.uuid = this.uuid
        requiredPayload.factory = this.selectedFactory.id
      } else {
        requiredPayload.uuid = this.uuid
        requiredPayload.name = this.name
        requiredPayload.no = this.no
        requiredPayload.province = this.selectedProvince
        requiredPayload.lat = this.lat
        requiredPayload.lon = this.lon
      }
      const result = await this.$store.dispatch('eReport/setEReport', requiredPayload)
      if (result === true) {
        // success
        this.sendSuccessSignal()
        this.querySuccess = true
      } else {
        this.errorMessage = result
        this.queryFail = true
      }
    },
    sendSuccessSignal () {
      this.$emit('success', true)
    },
    async searchFactory (keyword) {
      if (this.isSearching) {
        return false
      }
      this.isSearching = true
      this.factorySearchResult = await this.$store.dispatch('ref/searchEntireFactory', { keyword: keyword })
      this.isSearching = false
    }
  },
  watch: {
    async isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
      if (newVal === true) {
        this.querySuccess = false
        this.queryFail = false
        this.errorMessage = ''
        if (this.$refs.form) {
          this.$refs.form.resetValidation()
          this.$refs.form.reset()
        }
        if (this.data.id) { // mode => edit or view
          await this.get()
        } else {
          this.resetForm()
        }
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
    },
    isSamplingPoint (to, from) {
      if (to !== 'new') {
        this.no = ''
        this.name = ''
        this.selectedProvince = null
        this.lat = ''
        this.lon = ''
      }
    },
    factorySearchKeyword (to, from) {
      if (to && to !== from) {
        if (String(to).length > 2 && to !== this.selectedFactory.name) {
          this.searchFactory(to)
        }
      }
    }
  },
  computed: {
    ...mapState(['province']),
    ...mapState('ref', ['factory']),
    ...mapState('user', ['uuid'])
  }
}
</script>

<style scoped>
.airDialogTable > tr > td {
  padding: 6px 4px;
}

@media (max-width: 960px) {
  .innerRow {
    margin: 0 0 -12px 0 !important;
  }
}

</style>
