<template>
  <div style="height: 100%;">
    <v-container class="pb-0" style="height: 100%;">
      <div id="ereport-noisePollution-header" class="d-flex flex-wrap pt-2" style="width: 100%;">
        <div class="d-none d-sm-block">
          <span style="font-size: 24px; color: #00236F;">ระดับเสียง</span>
        </div>
        <v-spacer class="d-flex d-sm-none"/>
        <div class="CRUD_box">
          <v-btn :disabled="uuid === ''" class="addButton"
                 @click="addItem()">
            <v-icon>mdi-plus</v-icon>
            เพิ่มสถานที่ตรวจวัด
          </v-btn>
          <v-spacer v-if="$vuetify.breakpoint.width > 600"/>
          <div class="pl-0 ml-0 ml-sm-3 py-sm-0" style="width: 250px;">
            <v-text-field v-model="keyword" :disabled="uuid === ''" append-icon="mdi-close" dense hide-details outlined
                          placeholder="สถานที่เก็บตัวอย่าง" prepend-inner-icon="mdi-magnify"
                          @click:append="keyword=''" @click:prepend-inner="search()"
                          @keydown.enter="search()"></v-text-field>
          </div>
          <div class="pl-0 ml-0 ml-sm-3 pt-2 py-sm-0" style="width: 250px;">
            <v-autocomplete v-model="selectedParameter" :disabled="uuid === ''" :items="parameterList" item-text="name" item-value="id"
                            dense hide-details outlined placeholder="พารามิเตอร์" multiple chips deletable-chips small-chips
                            @blur="search()"></v-autocomplete>
          </div>
        </div>
      </div>
      <div :style="`height: calc(100% - ${headerHeight}px);`" class="pt-5">
        <div style="width: 100%; text-align: right; font-size: 13px;">รายการที่ 1 - {{ dataList.length }} | ทั้งหมด {{ itemCount }} รายการ</div>
        <v-data-table id="noisePollution_dataTable" :headers="headers" :items="dataList" :items-per-page="999"
                      disable-sort fixed-header hide-default-footer mobile-breakpoint="0" style="height: calc(100% - 20px);">
          <template v-slot:item.samplingLocationName="{ item }">
            <a class="clickText" @click="goToDetail(item)">{{ item.samplingLocationName }}</a>
          </template>
          <template v-slot:item.actions="{ item }">
<!--            <v-icon class="mr-2" @click="viewItem(item)">-->
<!--              mdi-magnify-->
<!--            </v-icon>-->
            <v-icon class="mr-2 myPencil" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon  class="myTrash" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.latestUpdatedDate="{ item }">
            {{ dateToThai((item.latestUpdatedDate || '').split(' ')[0]) }}
          </template>
          <template v-slot:no-data>
            <div class="d-flex align-center justify-center" style="height: 48px;">
              <span v-if="uuid === ''" class="grey--text">
                กรุณาเข้าสู่ระบบเพื่อใช้งาน
              </span>
              <span v-else>ไม่พบข้อมูล</span>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <air-dialog :isShow="openDialog" :mode="dialogMode" :data="dialogData" :type="4"
                v-on:isShowSignal="handleDialogSignal" v-on:success="search()"></air-dialog>
    <v-dialog v-model="openDeleteDialog" max-width="600">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="openDeleteDialog=false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="py-9 black--text text-center" style="font-size: 20px;">
            <span v-if="!isFinishDelete && !isFailDelete">
              ยืนยันการลบ
            </span>
            <span v-else-if="isFinishDelete" class="success--text">ลบสำเร็จ</span>
            <span v-else-if="isFailDelete" class="warning--text">ลบไม่สำเร็จ</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn elevation="0" @click="openDeleteDialog=false">
              ปิด
            </v-btn>
            <v-btn v-if="!isFinishDelete && !isFailDelete" class="ml-3" color="search-item white--text" elevation="0"
                   @click="confirmDelete()">
              ยืนยัน
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AirDialog from '../dialog/ereport/airDialog'
import utils from '../../assets/js/utils'

export default {
  name: 'noisePollution',
  components: { AirDialog },
  async mounted () {
    this.reCalculateHeight()
    window.addEventListener('resize', this.reCalculateHeight)

    this.parameterList = await this.$store.dispatch('ref/getEntireParameter', { type: 4 })
    await this.get()

    const dom = document.getElementById('noisePollution_dataTable')
    const target = dom.getElementsByClassName('v-data-table__wrapper')[0]
    target.addEventListener('scroll', this.onAirListScroll, { passive: true })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.reCalculateHeight)
  },
  data () {
    return {
      parameterList: [],
      headerHeight: 40,
      headers: [
        {
          text: 'จังหวัด',
          value: 'provinceName',
          sortable: false
        },
        {
          text: 'เลขทะเบียนโรงงาน',
          value: 'factoryCode',
          sortable: false
        },
        {
          text: 'สถานที่เก็บตัวอย่าง',
          value: 'samplingLocationName',
          sortable: false
        },
        {
          text: 'วันที่มีการอัพเดทข้อมูลล่าสุด',
          value: 'latestUpdatedDate',
          sortable: false,
          width: 115
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 95
        }
      ],
      dataList: [],
      openDialog: false,
      dialogMode: '',
      openDeleteDialog: false,
      isFinishDelete: false,
      isFailDelete: false,
      dialogData: {},
      toBeDeleteId: 0,
      // --
      currentPage: 1,
      maxPage: 0,
      itemCount: 0,
      hasNextPage: false,
      // --
      keyword: '',
      selectedParameter: [],
      selectedProvince: 0
    }
  },
  methods: {
    reCalculateHeight () {
      const dom = document.getElementById('ereport-noisePollution-header')
      if (dom) {
        this.headerHeight = dom.offsetHeight
      }
    },
    onAirListScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight * 0.9 && !this.isFetching && this.hasNextPage) {
        this.currentPage += 1
        this.get()
      }
    },
    async get () {
      this.isFetching = true
      const payload = { uuid: this.uuid, type: 4 }
      const optPayload = { keyword: this.keyword, parameter: this.selectedParameter, province: this.selectedProvince || '', page: this.currentPage }
      const nextData = await this.$store.dispatch('eReport/getEReportList', { ...payload, ...optPayload })
      let nextItem = []
      if (Array.isArray(nextData)) {
        nextItem = nextData
      } else {
        nextItem = nextData.items
      }
      if (this.currentPage === 1) {
        this.maxPage = nextData.maxPage || 0
        this.itemCount = nextData.itemCount || 0
        if (Array.isArray(nextItem) && nextItem.length > 0) {
          this.dataList = nextItem
        }
      } else {
        this.dataList = [...this.dataList, ...(nextItem || [])]
      }
      this.hasNextPage = nextData.hasNextPage || false
      this.isFetching = false
    },
    async search () {
      this.currentPage = 1
      this.dataList = []
      await this.get()
    },
    goToDetail (item) {
      this.$router.push({ name: 'ViewReport', query: { id: item.id, isSamplingPoint: item.isSamplingPoint, type: '4' } })
    },
    addItem () {
      this.dialogData = { id: 0 }
      this.openDialog = true
      this.dialogMode = 'add'
    },
    viewItem (item) {
      this.dialogData = item
      this.dialogMode = 'view'
      this.openDialog = true
    },
    editItem (item) {
      this.dialogData = item
      this.dialogMode = 'edit'
      this.openDialog = true
    },
    deleteItem (item) {
      this.toBeDeleteId = item.id
      this.isFinishDelete = false
      this.isFailDelete = false
      this.openDeleteDialog = true
    },
    async confirmDelete () {
      const result = await this.$store.dispatch('eReport/deleteEReport', { uuid: this.uuid, sampling_location_id: this.toBeDeleteId })
      if (result) {
        this.isFinishDelete = true
        this.toBeDeleteId = 0
        await this.search()
      } else {
        this.isFailDelete = true
        this.toBeDeleteId = 0
      }
    },
    handleDialogSignal (e) {
      if (e !== this.openDialog) {
        this.openDialog = e
      }
    },
    dateToThai (data) {
      return utils.dateToThai(data)
    }
  },
  computed: {
    ...mapState('user', ['uuid', 'permission'])
  }
}
</script>

<style scoped>
.myPencil:hover {
  color: #ffc700;
}

.myTrash:hover {
  color: #bf1c12;
}

@media (max-width: 600px) and (min-width: 830px) {
  .CRUD_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 250px;
  }

  .addButton {
    margin: 0 0 12px 0;
  }
}

@media (max-width: 830px) {
  .CRUD_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: calc(100% - 105px);
  }

  .addButton {
    margin: 0 0 12px 24px;
  }
}

@media (min-width: 830px) {
  .CRUD_box {
    display: flex;
    width: calc(100% - 105px);
  }

  .addButton {
    margin: 0 24px 0 24px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  ::v-deep .container {
    max-width: 960px;
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  /*height: unset;*/
  min-height: 48px;
  padding: 4px 16px;
}

::v-deep .v-data-table__wrapper {
  /*height:calc(100% - 150px) !important;*/
  max-height: 100%;
  overflow-y: unset;
}

::v-deep .v-data-table-header > tr > th {
  background-color: #D8D8D8 !important;
  padding: 0 8px !important;
  color: black;
}

::v-deep .v-data-table-header > tr > th {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
  border-top: thin solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table-header > tr > th:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:first-child > td {
  border-top: unset;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
  border-right: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:last-child > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.3) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:not(:first-child) {
  border-left: thin solid rgba(0, 0, 0, 0.12) !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:hover {
  background-color: #e5eeff !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background-color: #F6F6F6;
}
</style>
