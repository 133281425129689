<template>
  <div class="fill-height">
    <div class="d-block d-sm-none">
      <v-btn :ripple="false" class="pl-0" color="white" elevation="0" large @click="$emit('openMenu', true)">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs" background-color="primary" centered hide-slider>
      <v-tab class="white--text px-8">
        สรุปภาพรวมประจำวัน
        <a class="a_forRightClick" href="?tab=0" @click.prevent></a>
        <!-- @click.prevent is use to prevent default click action -->
      </v-tab>
      <v-tab class="white--text px-8">
        ส่งออกข้อมูล
        <a class="a_forRightClick" href="?tab=1" @click.prevent></a>
        <!-- @click.prevent is use to prevent default click action -->
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="contentItem" touchless>
      <v-tab-item>
        <div style="height: 100%">
          <daily-e-report></daily-e-report>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div style="height: 100%">
          <export-e-report></export-e-report>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DailyEReport from '../components/ereport/DailyEReport'
import ExportEReport from '../components/ereport/ExportEReport'

export default {
  name: 'StatisticEReport',
  components: { ExportEReport, DailyEReport },
  beforeMount () {
    const tab = this.$route.query.tab
    if (tab) {
      this.tab = parseInt(tab)
    }
  },
  data () {
    return {
      tab: 0
    }
  },
  watch: {
    tab (to, from) {
      if (to !== from) {
        this.$router.replace({ name: this.$route.name, query: { tab: to } })
          .catch(() => {}) // remove redundant error
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .contentItem {
    height: calc(100% - 48px - 44px);
  }
}

@media (min-width: 600px) {
  .contentItem {
    height: calc(100% - 48px);
  }
}

::v-deep .v-slide-group__next > i {
  color: white !important;
}

::v-deep .v-slide-group__next > .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0) !important;
}

::v-deep .v-slide-group__prev > i {
  color: white !important;
}

::v-deep .v-slide-group__prev > .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0) !important;
}

::v-deep .v-window__container, .v-window-item {
  height: 100%;
}

::v-deep .v-tab--active {
  background: rgb(158, 137, 77);
  background: -moz-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9e894d", endColorstr="#b7a676", GradientType=1);
}
</style>
