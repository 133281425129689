<template>
  <div style="height: 100%; max-height: 100%">
    <div class="d-flex flex-wrap justify-sm-center mt-3"
         style="width: 100%; background-color: rgba(65, 149, 24, 0.2);">
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          วันที่เริ่ม
        </div>
        <div class="myInputWidth pr-3 py-2">
          <my-date-picker v-model="data.datefrom" min=""></my-date-picker>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          วันที่สิ้นสุด
        </div>
        <div class="myInputWidth py-2 pr-3 pr-sm-0">
          <my-date-picker v-model="data.dateto" :min="data.datefrom"></my-date-picker>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          จังหวัด
        </div>
        <div class="myInputWidth py-2 pr-3 pr-sm-0">
          <v-autocomplete v-model="data.geocode" :items="forSelectProvince" background-color="white" color="search"
                          dense hide-details item-color="search-item" item-text="name" item-value="id" outlined
                          placeholder="ทั้งหมด"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          รหัสประเภทโรงงาน
        </div>
        <div class="myInputWidth py-2 pr-3 pr-sm-0">
          <v-autocomplete v-model="data.type_factory" :items="forSelectFactoryType" background-color="white"
                          color="search" dense hide-details item-color="search-item" item-text="name" item-value="id"
                          outlined placeholder="ทั้งหมด"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          สถานที่ตรวจวัด
        </div>
        <div class="myInputWidth py-2 pr-3 pr-sm-0">
          <v-autocomplete v-model="data.sampling_location" :items="forSelectSamplingLocation" background-color="white"
                          color="search" dense hide-details item-color="search-item" item-text="name" item-value="id"
                          outlined placeholder="ทั้งหมด"></v-autocomplete>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          ประเภทการตรวจวัด
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.measurementType" :items="forSelectMeasurementType" background-color="white"
                    color="search" dense hide-details item-color="search-item" item-text="name" item-value="id" outlined
                    placeholder="ทั้งหมด" @change="data.parameter=''"></v-select>
        </div>
      </div>

      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          ประเภทจุดเก็บ
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.sampling_point_type" :items="samplingPointArray" background-color="white"
                    color="search" dense hide-details item-color="search-item" item-text="name" item-value="id" outlined
                    placeholder="ทั้งหมด"></v-select>
        </div>
      </div>

      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          เชื้อเพลิงที่ใช้
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.stack_fuel" :items="forSelectStackFuelRef" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          พารามิเตอร์
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.parameter" :items="forSelectParameter" background-color="white" dense hide-details
                    item-text="name" item-value="id" outlined placeholder="ทั้งหมด"></v-select>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="myLabelWidth d-flex align-center search--text pl-3 pr-3 py-2">
          หน่วยพารามิเตอร์
        </div>
        <div class="myInputWidth pr-3 py-2">
          <v-select v-model="data.unit"
                    :items="(forSelectParameter.find(item => item.id === data.parameter) || { unit: [] }).unit"
                    background-color="white" dense hide-details item-text="name" item-value="id" outlined
                    placeholder="ทั้งหมด"></v-select>
        </div>
      </div>
      <div class="pl-sm-3 py-2">
        <v-btn color="primary white--text" style="height: 40px;" @click="getExportUrl()">ส่งออก</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MyDatePicker from '../myDatePicker'
import utils from '../../assets/js/utils'

export default {
  name: 'ExportEReport',
  components: { MyDatePicker },
  mounted () {
    this.data.measurementType = 1
    this.$store.dispatch('getProvince')
    this.$store.dispatch('ref/getFactoryType')
    this.$store.dispatch('ref/getStackFuelRef')
    this.$store.dispatch('ref/getSamplingLocation', {}).then(
      data => {
        this.samplingLocationArray = data
      }
    )
    this.$store.dispatch('ref/getEReportSamplingType', { type: 1 }).then(
      data => {
        this.samplingPointArray = data
      }
    )
  },
  data () {
    return {
      measurementType: [
        { id: 1, name: 'น้ำ' },
        { id: 2, name: 'แหล่งน้ำธรรมชาติ' },
        { id: 3, name: 'อากาศ' },
        { id: 4, name: 'ระดับเสียง' }
      ],
      data: {
        datefrom: new Date().toISOString().substr(0, 10),
        dateto: new Date().toISOString().substr(0, 10),
        geocode: null,
        sampling_location: null,
        sampling_point_type: null,
        measurementType: null,
        stack_fuel: null,
        parameter: null,
        unit: null,
        type_factory: null
      },
      samplingPointArray: [],
      samplingLocationArray: [],
      frequentList: [
        {
          id: 'hourly',
          name: 'รายชั่วโมง'
        },
        {
          id: 'daily',
          name: 'รายวัน'
        },
        {
          id: 'monthly',
          name: 'รายเดือน'
        },
        {
          id: 'annual',
          name: 'รายปี'
        }
      ],
      factoryMeasurement: [],
      filteredParameter: [],
      isFetching: false
    }
  },
  methods: {
    getExportUrl () {
      const payload = {
        uuid: this.uuid,
        datefrom: this.data.datefrom || '',
        dateto: this.data.dateto || '',
        geocode: this.data.geocode || '',
        sampling_location: this.data.sampling_location || '',
        sampling_point_type: this.data.sampling_point_type || '',
        type: this.data.measurementType || '',
        stack_fuel: this.data.stack_fuel || '',
        parameter: this.data.parameter || '',
        unit: this.data.unit || '',
        type_factory: this.data.type_factory || ''
      }
      Object.keys(payload).forEach(key => {
        if (!payload[key]) delete payload[key]
      })
      const query = new URLSearchParams(payload).toString()
      const url = process.env.VUE_APP_WS_BASE + '/export/ereport?' + query
      console.log(url)
      if (utils.getDeviceType() === 3) {
        window.open(url, 'Download')
      } else {
        window.longdoJsInterface.openUrl({ url: url, title: 'ส่งออกข้อมูล' })
          .then(
            (result) => {
              if (!result) console.warn('open url not success with unknown error')
            },
            (error) => {
              if (error.code !== 1) console.warn('open url not success with this code', error)
            }
          )
      }
    }
  },
  computed: {
    ...mapState('ref', ['factory', 'factoryType', 'stackFuelRef']),
    ...mapState('user', ['uuid', 'roles', 'factory_id', 'province_id']),
    ...mapState(['province']),
    forSelectMeasurementType () {
      return this.measurementType
    },
    forSelectStackFuelRef () {
      if (this.stackFuelRef.length > 1) {
        return [{ id: 0, name: 'ทั้งหมด' }, ...this.stackFuelRef]
      } else {
        return []
      }
    },
    forSelectParameter () {
      if (this.filteredParameter.length > 1) {
        return [{ id: 0, name: 'ทั้งหมด' }, ...this.filteredParameter]
      } else {
        return []
      }
    },
    forSelectProvince () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.province]
    },
    forSelectFactoryType () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.factoryType]
    },
    forSelectSamplingLocation () {
      if (this.samplingLocationArray.length > 1) {
        return [{ id: 0, name: 'ทั้งหมด' }, ...this.samplingLocationArray]
      } else {
        return []
      }
    },
    sampleLocationWatcherIndicator () {
      return this.data.type_factory + '_' + this.data.geocode
    }
  },
  watch: {
    async 'data.measurementType' (to, from) {
      if (!to) {
        this.filteredParameter = await this.$store.dispatch('ref/getSubeteEntireParameter')
      } else {
        this.$store.dispatch('ref/getEReportSamplingType', { type: to }).then(
          data => {
            this.samplingPointArray = data
          }
        )
        this.filteredParameter = await this.$store.dispatch('ref/getEntireParameter', { type: to })
      }
    },
    async sampleLocationWatcherIndicator (to, from) {
      const payload = {}
      if (this.data.type_factory) {
        payload.type = this.data.type_factory
      }
      if (this.data.geocode) {
        payload.province = this.data.geocode
      }
      this.$store.dispatch('ref/getSamplingLocation', payload).then(
        data => {
          this.samplingLocationArray = data
        }
      )
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .myLabelWidth {
    width: 105px;
  }

  .myInputWidth {
    max-width: calc(100vw - 12px - 105px - 86px);
  }
}

@media (min-width: 600px) {
  .myInputWidth {
    max-width: 170px;
  }
}

::v-deep .v-select__selections {
  line-height: unset;
}

::v-deep .v-list-item__content > * {
  line-height: unset;
}
</style>
